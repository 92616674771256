import { Duration, ProductSKUType, RenewalType, SKU, SubscriptionModel } from './SkuEnums';

export const SkuToConfig = {
	[SKU.B2B_SELFSERVE_1Y]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.PREMIUM_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.PREMIUM_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.PREMIUM_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.PREMIUM_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.PREMIUM_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.PREMIUM_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.PREMIUM_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.PREMIUM_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.PREMIUM_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.PREMIUM_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.PREMIUM_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.PREMIUM_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.PREMIUM_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.PREMIUM_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
	[SKU.CALM_HEALTH_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CALM_HEALTH_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CALM_HEALTH_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CALM_HEALTH_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.CALM_HEALTH_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CALM_HEALTH_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CALM_HEALTH_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CALM_HEALTH_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CALM_HEALTH_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CALM_HEALTH_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.CALM_HEALTH_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CALM_HEALTH_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CALM_HEALTH_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.CALM_HEALTH_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_DIRECT,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
	[SKU.CH_PAYOR_BASE_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BASE_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BASE_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BASE_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BASE_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BASE_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BASE_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BASE_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BASE_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BASE_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BASE_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BASE_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BASE_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BASE_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
	[SKU.CH_PAYOR_BUYUP_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BUYUP_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BUYUP_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BUYUP_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.CH_PAYOR_BUYUP_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BUYUP_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BUYUP_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.CH_PAYOR_BUYUP_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BUYUP_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BUYUP_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.CH_PAYOR_BUYUP_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BUYUP_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BUYUP_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.CH_PAYOR_BUYUP_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.CH_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
	[SKU.C4B_PAYOR_BASE_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth, // ???
	},
	[SKU.C4B_PAYOR_BASE_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BASE_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BASE_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BASE_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BASE_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BASE_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BASE_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BASE_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BASE_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BASE_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BASE_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BASE_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BASE_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BASE,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
	[SKU.C4B_PAYOR_BUYUP_CUSTOM]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Custom,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth, // ???
	},
	[SKU.C4B_PAYOR_BUYUP_1M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_1M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_1M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_3M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_3M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_3M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.ThreeMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_6M]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_6M_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_6M_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.SixMonth,
	},
	[SKU.C4B_PAYOR_BUYUP_1Y]: {
		shouldRenew: false,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BUYUP_1Y_RENEWABLE]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Renewable,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BUYUP_1Y_AUTORENEW]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.OneYear,
	},
	[SKU.C4B_PAYOR_BUYUP_LIFETIME]: {
		shouldRenew: true,
		productSKUType: ProductSKUType.C4B_PAYOR_BUYUP,
		subscriptionAccessModel: SubscriptionModel.Fixed,
		renewalType: RenewalType.Autorenew,
		duration: Duration.Lifetime,
	},
};
