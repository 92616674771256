import { isEqual } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { JSONValue } from '@/types/json';

import { ApiResponse } from './types';

export interface FeatureFlags {
	[name: string]: JSONValue;
}

export interface ApiFeatureFlag {
	name: string;
	value: JSONValue;
}

/**
 * Usage:
    const flagInfo = useFeatureFlags('b2b-user-onboarding-fixture', 'amex-intl-promotion-launch');
    if (flagInfo.data['b2b-user-onboarding-fixture'] === 'enabled') { ... }
 *
 * Notes:
   * You may request flags that have no value. In that case, `flagInfo.data['nonce']` will be `undefined`
   * Flag values can be any JSON value, including `null`, arrays, and rich (JSON) objects
   * For render efficiency reasons, it may return more flags than you ask for
 */

export function useFeatureFlags(...flags: string[]): ApiResponse<FeatureFlags> {
	const apiRequest = useApi();
	const [cachedFlags, setCachedFlags] = useState<FeatureFlags>({});
	const params = useParams();

	const apiEndpoint = ((): string => {
		if (flags) {
			return `b2b/feature-flags?filter[name]=${flags.join(',')}&partner_id=${params.partnerId}`;
		}
		return `b2b/feature-flags?partner_id=${params.partnerId}`;
	})();
	const { data, error } = useSWR(
		apiEndpoint,
		async endpoint => {
			const response = await apiRequest({ endpoint });
			if (!response.data) {
				throw new Error('Not able to fetch feature flags');
			}
			return response.data;
		},
		{ errorRetryCount: 0 },
	);
	let newCachedFlags = cachedFlags;
	if (data && !error) {
		const apiFlags = data?.feature_flags as ApiFeatureFlag[];
		newCachedFlags = apiFlags.reduce(
			(acc, apiFlag) => {
				acc[apiFlag.name] = apiFlag.value;
				return acc;
			},
			{ ...cachedFlags },
		);
		if (isEqual(newCachedFlags, cachedFlags)) {
			// Keep it === if nothing has changed
			newCachedFlags = cachedFlags;
		} else {
			setCachedFlags(newCachedFlags);
		}
	}
	return { data: newCachedFlags, error, loading: !data && !error };
}

export function isEnabledForPartner(flagValues: FeatureFlags, name: string, id: string): boolean {
	const flag = flagValues[name];
	if (!flag) {
		return false;
	}
	const match = Object.entries(flag).find(([k]) => k === id);
	const isEnabled = match ? match[1] === true : false;
	return isEnabled;
}

export enum FeatureFlagNames {
	B2B_PARTNER_HOMEPAGE = 'b2b-partner-homepage',
	SFTP_INTEGRATION_FF = 'b2b-sftp-integration',
	CALM_HEALTH_SFTP_INTEGRATION = 'partner-portal-calm-health-sftp-configuration',
	B2B_PARENT_PARTNER = 'b2b-parent-partner',
	B2B_SELF_SERVE_TAX_COLLECTION = 'b2b-self-serve-tax-collection',
	B2B_SELF_SERVE_TAXES_ON_UPGRADES = 'b2b-self-serve-taxes-on-upgrades',
}
