export enum SKU {
	// Self Serve SKUs
	B2B_SELFSERVE_1Y = 'b2b_selfserve_1y',

	// Calm for Business / Premium SKUs
	PREMIUM_CUSTOM = 'premium_custom',
	PREMIUM_1M = 'premium_1m',
	PREMIUM_1M_RENEWABLE = 'premium_1m_renewable',
	PREMIUM_1M_AUTORENEW = 'premium_1m_autorenew',
	PREMIUM_3M = 'premium_3m',
	PREMIUM_3M_RENEWABLE = 'premium_3m_renewable',
	PREMIUM_3M_AUTORENEW = 'premium_3m_autorenew',
	PREMIUM_6M = 'premium_6m',
	PREMIUM_6M_RENEWABLE = 'premium_6m_renewable',
	PREMIUM_6M_AUTORENEW = 'premium_6m_autorenew',
	PREMIUM_1Y = 'premium_1y',
	PREMIUM_1Y_RENEWABLE = 'premium_1y_renewable',
	PREMIUM_1Y_AUTORENEW = 'premium_1y_autorenew',
	PREMIUM_LIFETIME = 'premium_lifetime',

	C4B_PAYOR_BASE_CUSTOM = 'c4b-payor-base_custom',
	C4B_PAYOR_BASE_1M = 'c4b-payor-base_1m',
	C4B_PAYOR_BASE_1M_RENEWABLE = 'c4b-payor-base_1m_renewable',
	C4B_PAYOR_BASE_1M_AUTORENEW = 'c4b-payor-base_1m_autorenew',
	C4B_PAYOR_BASE_3M = 'c4b-payor-base_3m',
	C4B_PAYOR_BASE_3M_RENEWABLE = 'c4b-payor-base_3m_renewable',
	C4B_PAYOR_BASE_3M_AUTORENEW = 'c4b-payor-base_3m_autorenew',
	C4B_PAYOR_BASE_6M = 'c4b-payor-base_6m',
	C4B_PAYOR_BASE_6M_RENEWABLE = 'c4b-payor-base_6m_renewable',
	C4B_PAYOR_BASE_6M_AUTORENEW = 'c4b-payor-base_6m_autorenew',
	C4B_PAYOR_BASE_1Y = 'c4b-payor-base_1y',
	C4B_PAYOR_BASE_1Y_RENEWABLE = 'c4b-payor-base_1y_renewable',
	C4B_PAYOR_BASE_1Y_AUTORENEW = 'c4b-payor-base_1y_autorenew',
	C4B_PAYOR_BASE_LIFETIME = 'c4b-payor-base_lifetime',

	C4B_PAYOR_BUYUP_CUSTOM = 'c4b-payor-buyup_custom',
	C4B_PAYOR_BUYUP_1M = 'c4b-payor-buyup_1m',
	C4B_PAYOR_BUYUP_1M_RENEWABLE = 'c4b-payor-buyup_1m_renewable',
	C4B_PAYOR_BUYUP_1M_AUTORENEW = 'c4b-payor-buyup_1m_autorenew',
	C4B_PAYOR_BUYUP_3M = 'c4b-payor-buyup_3m',
	C4B_PAYOR_BUYUP_3M_RENEWABLE = 'c4b-payor-buyup_3m_renewable',
	C4B_PAYOR_BUYUP_3M_AUTORENEW = 'c4b-payor-buyup_3m_autorenew',
	C4B_PAYOR_BUYUP_6M = 'c4b-payor-buyup_6m',
	C4B_PAYOR_BUYUP_6M_RENEWABLE = 'c4b-payor-buyup_6m_renewable',
	C4B_PAYOR_BUYUP_6M_AUTORENEW = 'c4b-payor-buyup_6m_autorenew',
	C4B_PAYOR_BUYUP_1Y = 'c4b-payor-buyup_1y',
	C4B_PAYOR_BUYUP_1Y_RENEWABLE = 'c4b-payor-buyup_1y_renewable',
	C4B_PAYOR_BUYUP_1Y_AUTORENEW = 'c4b-payor-buyup_1y_autorenew',
	C4B_PAYOR_BUYUP_LIFETIME = 'c4b-payor-buyup_lifetime',

	// Calm Health SKUs
	CALM_HEALTH_CUSTOM = 'calm_health_custom',
	CALM_HEALTH_1M = 'calm_health_1m',
	CALM_HEALTH_1M_RENEWABLE = 'calm_health_1m_renewable',
	CALM_HEALTH_1M_AUTORENEW = 'calm_health_1m_autorenew',
	CALM_HEALTH_3M = 'calm_health_3m',
	CALM_HEALTH_3M_RENEWABLE = 'calm_health_3m_renewable',
	CALM_HEALTH_3M_AUTORENEW = 'calm_health_3m_autorenew',
	CALM_HEALTH_6M = 'calm_health_6m',
	CALM_HEALTH_6M_RENEWABLE = 'calm_health_6m_renewable',
	CALM_HEALTH_6M_AUTORENEW = 'calm_health_6m_autorenew',
	CALM_HEALTH_1Y = 'calm_health_1y',
	CALM_HEALTH_1Y_RENEWABLE = 'calm_health_1y_renewable',
	CALM_HEALTH_1Y_AUTORENEW = 'calm_health_1y_autorenew',
	CALM_HEALTH_LIFETIME = 'calm_health_lifetime',

	CH_PAYOR_BASE_CUSTOM = 'ch-payor-base_custom',
	CH_PAYOR_BASE_1M = 'ch-payor-base_1m',
	CH_PAYOR_BASE_1M_RENEWABLE = 'ch-payor-base_1m_renewable',
	CH_PAYOR_BASE_1M_AUTORENEW = 'ch-payor-base_1m_autorenew',
	CH_PAYOR_BASE_3M = 'ch-payor-base_3m',
	CH_PAYOR_BASE_3M_RENEWABLE = 'ch-payor-base_3m_renewable',
	CH_PAYOR_BASE_3M_AUTORENEW = 'ch-payor-base_3m_autorenew',
	CH_PAYOR_BASE_6M = 'ch-payor-base_6m',
	CH_PAYOR_BASE_6M_RENEWABLE = 'ch-payor-base_6m_renewable',
	CH_PAYOR_BASE_6M_AUTORENEW = 'ch-payor-base_6m_autorenew',
	CH_PAYOR_BASE_1Y = 'ch-payor-base_1y',
	CH_PAYOR_BASE_1Y_RENEWABLE = 'ch-payor-base_1y_renewable',
	CH_PAYOR_BASE_1Y_AUTORENEW = 'ch-payor-base_1y_autorenew',
	CH_PAYOR_BASE_LIFETIME = 'ch-payor-base_lifetime',

	CH_PAYOR_BUYUP_CUSTOM = 'ch-payor-buyup_custom',
	CH_PAYOR_BUYUP_1M = 'ch-payor-buyup_1m',
	CH_PAYOR_BUYUP_1M_RENEWABLE = 'ch-payor-buyup_1m_renewable',
	CH_PAYOR_BUYUP_1M_AUTORENEW = 'ch-payor-buyup_1m_autorenew',
	CH_PAYOR_BUYUP_3M = 'ch-payor-buyup_3m',
	CH_PAYOR_BUYUP_3M_RENEWABLE = 'ch-payor-buyup_3m_renewable',
	CH_PAYOR_BUYUP_3M_AUTORENEW = 'ch-payor-buyup_3m_autorenew',
	CH_PAYOR_BUYUP_6M = 'ch-payor-buyup_6m',
	CH_PAYOR_BUYUP_6M_RENEWABLE = 'ch-payor-buyup_6m_renewable',
	CH_PAYOR_BUYUP_6M_AUTORENEW = 'ch-payor-buyup_6m_autorenew',
	CH_PAYOR_BUYUP_1Y = 'ch-payor-buyup_1y',
	CH_PAYOR_BUYUP_1Y_RENEWABLE = 'ch-payor-buyup_1y_renewable',
	CH_PAYOR_BUYUP_1Y_AUTORENEW = 'ch-payor-buyup_1y_autorenew',
	CH_PAYOR_BUYUP_LIFETIME = 'ch-payor-buyup_lifetime',
}

export enum SubscriptionModel {
	Custom = 'custom',
	Fixed = 'fixed',
}

export enum ProductSKUType {
	C4B_DIRECT = 'PREMIUM',
	C4B_PAYOR_BASE = 'PREMIUM_PAYOR_BASE',
	C4B_PAYOR_BUYUP = 'PREMIUM_PAYOR_BUYUP',
	CH_DIRECT = 'HEALTH',
	CH_PAYOR_BASE = 'HEALTH_PAYOR_BASE',
	CH_PAYOR_BUYUP = 'HEALTH_PAYOR_BUYUP',
}

export enum ProductSKUDatabaseType {
	C4B_DIRECT = 'premium',
	C4B_PAYOR_BASE = 'c4b-payor-base',
	C4B_PAYOR_BUYUP = 'c4b-payor-buyup',
	CH_DIRECT = 'calm_health',
	CH_PAYOR_BASE = 'ch-payor-base',
	CH_PAYOR_BUYUP = 'ch-payor-buyup',
}

export enum RenewalType {
	Autorenew = 'autorenew',
	Renewable = 'renewable',
}

export enum Duration {
	OneMonth = '1m',
	ThreeMonth = '3m',
	SixMonth = '6m',
	OneYear = '1y',
	Lifetime = 'lifetime',
}

export const SHOULD_RENEW = 'shouldRenew';
export const NON_RENEW = 'nonRenew';
