import {
	Duration,
	ProductSKUType,
	RenewalType,
	SKU,
	SubscriptionModel,
	ProductSKUDatabaseType,
} from './SkuEnums';
import { SkuToConfig } from './SkuToConfig';
import { SKUToDisplayName } from './SkuToDisplayName';
import { ConfigToSku } from './configToSku';

export function getSku({
	subscriptionAccessModel,
	shouldRenew,
	duration,
	renewalType,
	productSKUType,
}: {
	subscriptionAccessModel: SubscriptionModel;
	shouldRenew: boolean;
	duration: Duration;
	renewalType: RenewalType;
	productSKUType: ProductSKUType;
}): SKU {
	// if custom, get config based on subscription model since duration on custom SKUs is not a defining characteristic
	if (subscriptionAccessModel === SubscriptionModel.Custom) {
		return ConfigToSku[subscriptionAccessModel][productSKUType];
	}

	const durationToSku = ConfigToSku[duration as Duration];
	// Non-renewals
	if (!shouldRenew) {
		return durationToSku.NON_RENEW[productSKUType];
	}
	return durationToSku.SHOULD_RENEW[renewalType][productSKUType];
}

export function getInitialSkuProps({ vouched_plan_sku }: { vouched_plan_sku: string | undefined }): {
	subscriptionAccessModel: SubscriptionModel;
	shouldRenew: boolean;
	renewalType: RenewalType;
	duration: Duration;
	productSKUType: ProductSKUType;
} {
	return vouched_plan_sku ? SkuToConfig[vouched_plan_sku as SKU] : SkuToConfig[SKU.PREMIUM_CUSTOM];
}

export function getSkuDisplayName(vouched_plan_sku: SKU | undefined): string {
	return vouched_plan_sku ? SKUToDisplayName[vouched_plan_sku] : SKUToDisplayName[SKU.PREMIUM_CUSTOM];
}

export function isSelfServePlan(sku?: string): boolean {
	return sku === SKU.B2B_SELFSERVE_1Y;
}

export function isCalmHealthSKU(vouched_plan_sku: string | undefined): boolean {
	const productSkuType = getInitialSkuProps({ vouched_plan_sku }).productSKUType;
	return (
		productSkuType === ProductSKUType.CH_DIRECT ||
		productSkuType === ProductSKUType.CH_PAYOR_BASE ||
		productSkuType === ProductSKUType.CH_PAYOR_BUYUP
	);
}

export function isCalmHealthProductSKU(product_sku: ProductSKUDatabaseType | undefined): boolean {
	return (
		product_sku === ProductSKUDatabaseType.CH_DIRECT ||
		product_sku === ProductSKUDatabaseType.CH_PAYOR_BASE ||
		product_sku === ProductSKUDatabaseType.CH_PAYOR_BUYUP
	);
}

export function isCalmHealthProductSKUType(product_sku: ProductSKUType | undefined): boolean {
	return (
		product_sku === ProductSKUType.CH_DIRECT ||
		product_sku === ProductSKUType.CH_PAYOR_BASE ||
		product_sku === ProductSKUType.CH_PAYOR_BUYUP
	);
}
